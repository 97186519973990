body {
  > .dropdown,
  > .dropup {
    z-index: 9 !important;
  }
}

.dropdown-menu {
  z-index: 9;

  @media (max-width: 992px) {
    z-index: 1052; // override to prevent chat icons from covering dropdowns
  }
}
