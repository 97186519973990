// This is the master of all form-control input filed borders and effects (hover and focus)
// We are using a absolute border (see _form.scss) around the input element because outline border-radius is currently not supported in safari.
// The default bootstrap border is static and does not look great imo when working with different border widths, and changing border widths.
// The bootstrap form-control border is therefore overwritten. For the future maybe we can remove form-control and form-group to use something more specific to our needs. Like a "py-form-control"

.form-control {
  //Remove bootstrap effects
  color: var(--py-color-black);
  font-family: var(--py-font-secondary);

  &:focus,
  &:active {
    outline: initial;
    box-shadow: initial;
    border-color: var(--py-color-grey-light);
  }
  &:disabled {
    color: var(--py-color-grey-dark);
  }

  &.is-invalid {
    background-image: none;
  }

  &.input-type-effects-textarea {
    border: 0;

    textarea {
      outline: 0;
      border-radius: 0.25rem;

      &:focus-visible {
        outline: none;
      }
    }
  }

  @include form-input-border;

  &:not(.is-disabled):not(.is-invalid) {
    &.input-type-effects-textarea,
    &.input-type-effects-text,
    &.input-type-effects-email,
    &.input-type-effects-password {
      border: 0;

      &:hover {
        @include form-input-hover;
      }

      &:focus-within {
        @include form-input-focus;
      }
    }
  }

  &.is-invalid:not(.is-disabled) {
    border: 0;
    padding: 0 !important;
    @include form-input-error;

    &:not(.hide-focus) {
      &.input-type-effects-textarea,
      &.input-type-effects-text,
      &.input-type-effects-email,
      &.input-type-effects-password {
        &:focus-within {
          @include form-input-focus-error;
        }
      }
    }
  }

  &::placeholder {
    color: var(--py-color-grey-400);
  }

  input {
    width: 100%;
  }
}

.input-type-effects-group {
  .form-control {
    &.input-type-effects-text:hover,
    &.input-type-effects-password:hover {
      border: 0;
      box-shadow: none;
    }

    &.input-type-effects-text:focus-within,
    &.input-type-effects-password:focus-within {
      border: 0;
      box-shadow: none;
    }
  }

  input {
    border: 0;
  }

  @include form-input-border;

  &:hover {
    @include form-input-hover;
  }

  &:focus-within,
  &:focus-within {
    @include form-input-focus;
  }
}
// If the user is zooming out, we need add some padding inside the input-border to make up for the lost space.
py-storefront.zoomed-out .form-control:before {
  left: -3px !important;
  top: -3px !important;
  right: -3px !important;
  bottom: -3px !important;
}
