@mixin form-border($border-width: -1) {
  position: relative;
  z-index: 0;

  &:before {
    content: ' ';
    position: absolute;
    left: #{$border-width}px;
    top: #{$border-width}px;
    right: #{$border-width}px;
    bottom: #{$border-width}px;
    border-radius: 0.25rem;
    z-index: -1;
  }
}

@mixin form-input-shadow() {
  box-shadow: 0px 0px 10px var(--py-color-grey-200);
  transition: box-shadow 0.1s ease;
}

@mixin form-input-border() {
  @include form-border;

  &:before {
    border: 1px solid var(--py-color-line-soft);
  }
}

@mixin form-input-hover() {
  @include form-input-shadow;
  @include form-border;

  &:before {
    border: 1px solid var(--py-color-line-hard);
  }
}

@mixin form-input-focus() {
  @include form-input-shadow;
  @include form-border(-2);

  &:before {
    border: 2px solid var(--py-color-global-active);
  }
}

@mixin form-input-focus-error() {
  @include form-input-focus;

  &:before {
    border: 2px solid var(--py-color-danger-500);
  }
}

@mixin form-input-error() {
  @include form-border;

  &:before {
    border: 1px solid var(--py-color-danger-500);
  }
}

@mixin input-label-content() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-family: var(--py-font-secondary);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;

  color: var(--py-color-grey-500);
}
