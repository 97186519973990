.checkout-item-list {
  .list-content {
    max-height: 400px;
    overflow: scroll;
  }

  .ant-list-items {
    &:first-child {
      border-top: none;
    }
  }
}
