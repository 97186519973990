.ant-drawer-right {
  .ant-drawer-content-wrapper {
    max-width: 100vw;
    border-top: 5px solid var(--py-color-primary-dark);
  }
}

.ant-drawer-body {
  padding: 0;
  font-size: unset;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}
