.tooltip {
  .tooltip-inner {
    padding: 10px 10px;
    background-color: #333333 !important;
  }

  &.hint-tooltip-wrapper {
    .tooltip-inner {
      font-size: 14px;
      line-height: 18px;
      * + * {
        margin-top: 0.8em;
      }
    }
    @include media-breakpoint-up(sm) {
      .tooltip-inner {
        max-width: 100%;
      }
    }
  }

  .arrow {
    &::before {
      border-top-color: #333333 !important;
    }
  }
}
