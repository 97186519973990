.popover {
  border-radius: 8px;
  min-width: 335px !important;

  @include media-breakpoint-down(md) {
    min-width: auto !important;
  }

  border: none !important;
  box-shadow: 0 10px 40px -20px rgba(0, 0, 0, 0.25);

  .arrow {
    &::before {
      border: none !important;
    }
  }

  .popover-body {
    padding: 0px !important;
  }
}
