.ant-drawer-right .ant-drawer-content-wrapper {
  max-width: 100vw;
  border-top: 5px solid var(--py-color-primary-dark);
}

.ant-drawer-body {
  padding: 0;
  font-size: unset;
  overscroll-behavior: contain;
}
.ant-drawer-body::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

/* Bootstrap variable overrides */
/* Custom global variables */
/**
* Create a gap on wrapping flex items
*/
/* IE10+ CSS styles */
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-row:hover {
  background: #fafafa;
}

.ant-table {
  width: 100%;
  border-radius: 3px;
}

.ant-table-title {
  padding: 10px;
  font-size: 16px;
  line-height: 1.38;
  font-family: var(--py-font-secondary);
  color: var(--py-color-black);
  background-color: var(--py-color-white);
  display: none;
}
@media (max-width: 767.98px) {
  .ant-table-title {
    display: block;
    border-bottom: solid 1px var(--py-color-grey-light);
  }
}

@media (max-width: 767.98px) {
  .ant-table-tbody .ant-table-row {
    display: block;
    border: 0;
    border-bottom: solid 1px var(--py-color-grey-light);
  }
  .ant-table-tbody .ant-table-row .ant-table-cell {
    border: none;
    display: block;
    font-size: 16px;
    line-height: 1.5;
    padding-top: 0px;
    padding-bottom: 0px;
    font-family: var(--py-font-primary);
    color: var(--py-color-grey-dark);
  }
  .ant-table-tbody .ant-table-row .ant-table-cell::before {
    width: 50%;
    float: left;
    font-size: 14px;
    line-height: 1.57;
    font-family: var(--py-font-secondary);
    content: attr(data-label);
    color: var(--py-color-black);
  }
  .ant-table-tbody .ant-table-row .ant-table-cell:first-child {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .ant-table-tbody .ant-table-row .ant-table-cell:last-child {
    padding-bottom: 20px;
  }
}

.ant-table-row .ant-table-cell {
  font-size: 16px;
  line-height: 2;
  color: var(--py-color-black);
}

.ant-table-thead .ant-table-cell {
  font-family: var(--py-font-secondary-bold);
  color: var(--py-color-grey-dark);
  background-color: var(--py-color-white);
  font-size: 14px;
  border: 0;
}
@media (max-width: 767.98px) {
  .ant-table-thead .ant-table-cell {
    display: none;
  }
}

.ant-table-pagination {
  float: none;
  text-align: center;
  justify-content: center;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background: none;
  border: none;
  color: var(--py-color-black);
}
.ant-pagination-prev .ant-pagination-item-link a:hover,
.ant-pagination-next .ant-pagination-item-link a:hover {
  color: var(--py-color-black);
}

.ant-pagination-item {
  border: none;
  background: none;
}
.ant-pagination-item a {
  font-size: 14px;
  font-family: var(--py-font-secondary);
}
.ant-pagination-item a:hover {
  color: var(--py-color-black);
}

.ant-pagination-item-active {
  border-radius: 90px;
  background: var(--py-color-primary-dark);
}
.ant-pagination-item-active a {
  color: var(--py-color-white) !important;
}
.ant-pagination-item-active a:hover {
  color: var(--py-color-white);
}

.table-grey {
  font-family: var(--py-font-primary);
  font-size: 16px;
  width: 100%;
}
.table-grey thead th {
  font-family: var(--py-font-secondary);
  background-color: var(--py-color-grey-100);
  font-size: 14px !important;
  color: var(--py-color-grey-500);
  border-bottom: 0px;
}
.table-grey tbody tr {
  border-bottom: 1px solid var(--py-color-grey-100);
}
.table-grey th,
.table-grey td {
  padding: 1rem 0.75rem !important;
  text-align: left !important;
}
.table-grey-list {
  padding: 0 !important;
  border: 1px solid var(--py-color-grey-100);
}
.table-grey-list-header {
  background-color: var(--py-color-grey-100);
}
.table-grey-list-row-header {
  font-family: var(--py-font-secondary-bold);
}

.checkout-item-list .list-content {
  max-height: 400px;
  overflow: scroll;
}
.checkout-item-list .ant-list-items:first-child {
  border-top: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-header {
  padding: 1rem 1rem;
}
.modal-body {
  padding: 16px 30px 30px;
}
.modal-content {
  border-radius: 0;
  border: none;
}
.modal .close {
  font-size: 38px;
  font-weight: 100;
  bottom: 5px;
  position: relative;
  margin-inline-start: 0;
  margin-inline-end: 0;
  align-self: flex-end;
}
@media (max-width: 991.98px) {
  .modal .close {
    margin-bottom: 0;
  }
}
.modal[aria-hidden=false] {
  display: block;
}

.cx-modal-container {
  display: flex;
  align-items: center;
  margin: auto;
  height: 100%;
  overflow-y: auto;
  max-width: 768px !important;
  min-width: 768px !important;
}
@media (max-width: 767.98px) {
  .cx-modal-container {
    margin: 0 !important;
    min-width: 100% !important;
    max-width: 100% !important;
    height: 100%;
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  .cx-modal-container {
    max-width: 768px !important;
    min-width: 768px !important;
  }
}

.cx-modal-header {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-inline-end: 1.875rem;
  padding-bottom: 0;
  padding-inline-start: 1.875rem;
}

.cx-modal-content {
  background-color: var(--cx-color-inverse);
  width: 100%;
}

.cx-modal-footer {
  padding: 0px 27px 30px;
}

@media (min-width: 992px) {
  .cx-asm-dialog {
    max-width: 95% !important;
    min-width: 95% !important;
  }
}
.cx-asm-dialog .cx-modal-content {
  border-radius: 16px;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 768px !important;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 620px !important;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 900px !important;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px !important;
  }
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

/* Bootstrap variable overrides */
/* Custom global variables */
/**
* Create a gap on wrapping flex items
*/
/* IE10+ CSS styles */
py-modal {
  display: block;
}

.modal-dialog {
  padding-right: 15px;
  padding-left: 15px;
}

.modal[aria-hidden=false] {
  display: block;
}
.modal.on-top {
  z-index: 1060;
}
@media (min-width: 576px) {
  .modal.fixed-height .modal-content {
    height: 75vh;
  }
}

.btn {
  font-size: 1rem;
  line-height: 1.5em;
  padding: 0.7em 1.8em;
  border-radius: 4px;
  border-width: 2px;
  box-shadow: 0 5px 10px 0 rgba(18, 54, 84, 0.25);
}
.btn:disabled, .btn:disabled:hover, .btn:disabled:focus, .btn-link {
  box-shadow: none;
}
.btn:not(.btn-link):focus {
  box-shadow: 0 5px 10px 0 rgba(18, 54, 84, 0.25);
}
.btn:not(.btn-icon):not(.btn-sm) {
  min-width: 10em;
}
@media (max-width: 767.98px) {
  .btn:not(.btn-icon):not(.btn-sm) {
    min-width: 5em;
  }
}
.btn:not(.btn-icon) i {
  font-size: 1.15em;
}
.btn:not(.btn-icon) > i:first-child {
  margin-right: 5px;
}
.btn:not(.btn-icon) > i:last-child {
  margin-left: 5px;
}
.btn-sm {
  font-size: 0.875rem;
}
.btn-icon {
  padding: 0.3em;
  font-size: 1.4em;
  border-radius: 4px;
}
.btn-icon i {
  width: 1.5em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn i {
  vertical-align: middle;
  line-height: 0;
}
.btn i svg {
  vertical-align: baseline;
}

button.close {
  opacity: 1 !important;
  color: var(--py-color-black) !important;
}
button.close:hover {
  opacity: 1 !important;
  color: var(--py-color-black) !important;
}

.form-control {
  color: var(--py-color-black);
  font-family: var(--py-font-secondary);
  position: relative;
  z-index: 0;
}
.form-control:focus, .form-control:active {
  outline: initial;
  box-shadow: initial;
  border-color: var(--py-color-grey-light);
}
.form-control:disabled {
  color: var(--py-color-grey-dark);
}
.form-control.is-invalid {
  background-image: none;
}
.form-control.input-type-effects-textarea {
  border: 0;
}
.form-control.input-type-effects-textarea textarea {
  outline: 0;
  border-radius: 0.25rem;
}
.form-control.input-type-effects-textarea textarea:focus-visible {
  outline: none;
}
.form-control:before {
  content: " ";
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 0.25rem;
  z-index: -1;
}
.form-control:before {
  border: 1px solid var(--py-color-line-soft);
}
.form-control:not(.is-disabled):not(.is-invalid).input-type-effects-textarea, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-text, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-email, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-password {
  border: 0;
}
.form-control:not(.is-disabled):not(.is-invalid).input-type-effects-textarea:hover, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-text:hover, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-email:hover, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-password:hover {
  box-shadow: 0px 0px 10px var(--py-color-grey-200);
  transition: box-shadow 0.1s ease;
  position: relative;
  z-index: 0;
}
.form-control:not(.is-disabled):not(.is-invalid).input-type-effects-textarea:hover:before, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-text:hover:before, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-email:hover:before, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-password:hover:before {
  content: " ";
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 0.25rem;
  z-index: -1;
}
.form-control:not(.is-disabled):not(.is-invalid).input-type-effects-textarea:hover:before, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-text:hover:before, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-email:hover:before, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-password:hover:before {
  border: 1px solid var(--py-color-line-hard);
}
.form-control:not(.is-disabled):not(.is-invalid).input-type-effects-textarea:focus-within, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-text:focus-within, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-email:focus-within, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-password:focus-within {
  box-shadow: 0px 0px 10px var(--py-color-grey-200);
  transition: box-shadow 0.1s ease;
  position: relative;
  z-index: 0;
}
.form-control:not(.is-disabled):not(.is-invalid).input-type-effects-textarea:focus-within:before, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-text:focus-within:before, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-email:focus-within:before, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-password:focus-within:before {
  content: " ";
  position: absolute;
  left: -2px;
  top: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 0.25rem;
  z-index: -1;
}
.form-control:not(.is-disabled):not(.is-invalid).input-type-effects-textarea:focus-within:before, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-text:focus-within:before, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-email:focus-within:before, .form-control:not(.is-disabled):not(.is-invalid).input-type-effects-password:focus-within:before {
  border: 2px solid var(--py-color-global-active);
}
.form-control.is-invalid:not(.is-disabled) {
  border: 0;
  padding: 0 !important;
  position: relative;
  z-index: 0;
}
.form-control.is-invalid:not(.is-disabled):before {
  content: " ";
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 0.25rem;
  z-index: -1;
}
.form-control.is-invalid:not(.is-disabled):before {
  border: 1px solid var(--py-color-danger-500);
}
.form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-textarea:focus-within, .form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-text:focus-within, .form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-email:focus-within, .form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-password:focus-within {
  box-shadow: 0px 0px 10px var(--py-color-grey-200);
  transition: box-shadow 0.1s ease;
  position: relative;
  z-index: 0;
}
.form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-textarea:focus-within:before, .form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-text:focus-within:before, .form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-email:focus-within:before, .form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-password:focus-within:before {
  content: " ";
  position: absolute;
  left: -2px;
  top: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 0.25rem;
  z-index: -1;
}
.form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-textarea:focus-within:before, .form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-text:focus-within:before, .form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-email:focus-within:before, .form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-password:focus-within:before {
  border: 2px solid var(--py-color-global-active);
}
.form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-textarea:focus-within:before, .form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-text:focus-within:before, .form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-email:focus-within:before, .form-control.is-invalid:not(.is-disabled):not(.hide-focus).input-type-effects-password:focus-within:before {
  border: 2px solid var(--py-color-danger-500);
}
.form-control::placeholder {
  color: var(--py-color-grey-400);
}
.form-control input {
  width: 100%;
}

.input-type-effects-group {
  position: relative;
  z-index: 0;
}
.input-type-effects-group .form-control.input-type-effects-text:hover, .input-type-effects-group .form-control.input-type-effects-password:hover {
  border: 0;
  box-shadow: none;
}
.input-type-effects-group .form-control.input-type-effects-text:focus-within, .input-type-effects-group .form-control.input-type-effects-password:focus-within {
  border: 0;
  box-shadow: none;
}
.input-type-effects-group input {
  border: 0;
}
.input-type-effects-group:before {
  content: " ";
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 0.25rem;
  z-index: -1;
}
.input-type-effects-group:before {
  border: 1px solid var(--py-color-line-soft);
}
.input-type-effects-group:hover {
  box-shadow: 0px 0px 10px var(--py-color-grey-200);
  transition: box-shadow 0.1s ease;
  position: relative;
  z-index: 0;
}
.input-type-effects-group:hover:before {
  content: " ";
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 0.25rem;
  z-index: -1;
}
.input-type-effects-group:hover:before {
  border: 1px solid var(--py-color-line-hard);
}
.input-type-effects-group:focus-within, .input-type-effects-group:focus-within {
  box-shadow: 0px 0px 10px var(--py-color-grey-200);
  transition: box-shadow 0.1s ease;
  position: relative;
  z-index: 0;
}
.input-type-effects-group:focus-within:before, .input-type-effects-group:focus-within:before {
  content: " ";
  position: absolute;
  left: -2px;
  top: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 0.25rem;
  z-index: -1;
}
.input-type-effects-group:focus-within:before, .input-type-effects-group:focus-within:before {
  border: 2px solid var(--py-color-global-active);
}

py-storefront.zoomed-out .form-control:before {
  left: -3px !important;
  top: -3px !important;
  right: -3px !important;
  bottom: -3px !important;
}

.tooltip .tooltip-inner {
  padding: 10px 10px;
  background-color: #333333 !important;
}
.tooltip.hint-tooltip-wrapper .tooltip-inner {
  font-size: 14px;
  line-height: 18px;
}
.tooltip.hint-tooltip-wrapper .tooltip-inner * + * {
  margin-top: 0.8em;
}
@media (min-width: 576px) {
  .tooltip.hint-tooltip-wrapper .tooltip-inner {
    max-width: 100%;
  }
}
.tooltip .arrow::before {
  border-top-color: #333333 !important;
}

.popover {
  border-radius: 8px;
  min-width: 335px !important;
  border: none !important;
  box-shadow: 0 10px 40px -20px rgba(0, 0, 0, 0.25);
}
@media (max-width: 991.98px) {
  .popover {
    min-width: auto !important;
  }
}
.popover .arrow::before {
  border: none !important;
}
.popover .popover-body {
  padding: 0px !important;
}

.nav-tabs {
  border-bottom: none;
}
.nav-tabs.side-scroll {
  overflow-y: scroll;
  white-space: nowrap;
  flex-wrap: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.nav-tabs.side-scroll::-webkit-scrollbar {
  display: none;
}
.nav-tabs.side-scroll.show-shadow-left, .nav-tabs.side-scroll.show-shadow-right {
  position: static;
}
.nav-tabs.side-scroll.show-shadow-right::after, .nav-tabs.side-scroll.show-shadow-left::before {
  content: " ";
  position: absolute;
  right: 30px;
  top: 0;
  height: 100%;
  width: 28px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--py-color-white) 100%), linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.24) 100%);
}
.nav-tabs.side-scroll.show-shadow-left::before {
  right: unset;
  left: 30px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, var(--py-color-white) 100%), linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.24) 100%);
}
.nav-tabs.side-scroll .arrow-left,
.nav-tabs.side-scroll .arrow-right {
  display: none;
  cursor: pointer;
  position: absolute;
  margin-top: 17px;
  min-width: 20px;
  height: 20px;
  border: 2px solid var(--py-color-black);
  border-radius: 50%;
  z-index: 5;
}
.nav-tabs.side-scroll .arrow-left-background,
.nav-tabs.side-scroll .arrow-right-background {
  display: none;
  z-index: 4;
  background-color: var(--py-color-white);
  position: absolute;
  min-width: 30px;
  height: 100%;
}
.nav-tabs.side-scroll .arrow-right-background {
  right: 0;
}
.nav-tabs.side-scroll .arrow-left-background {
  left: 0;
}
.nav-tabs.side-scroll .arrow-left:after,
.nav-tabs.side-scroll .arrow-right:after {
  content: "";
  position: absolute;
  display: none;
  margin-top: 5px;
  margin-left: 5px;
  width: 6px;
  height: 6px;
  border-top: 2px solid var(--py-color-black);
  border-right: 2px solid var(--py-color-black);
  -moz-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.nav-tabs.side-scroll .arrow-right:after {
  content: "";
  margin-left: 4px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.nav-tabs.side-scroll .arrow-left {
  left: 7px;
}
.nav-tabs.side-scroll .arrow-right {
  right: 7px;
}
.nav-tabs.side-scroll.show-shadow-left .arrow-left,
.nav-tabs.side-scroll.show-shadow-left .arrow-left:after,
.nav-tabs.side-scroll.show-shadow-left .arrow-left-background {
  display: inline-block;
}
.nav-tabs.side-scroll.show-shadow-right .arrow-right,
.nav-tabs.side-scroll.show-shadow-right .arrow-right:after,
.nav-tabs.side-scroll.show-shadow-right .arrow-right-background {
  display: inline-block;
}
.nav-tabs.side-scroll .arrow-left:hover,
.nav-tabs.side-scroll .arrow-right:hover {
  background-color: var(--py-color-black);
}
.nav-tabs.side-scroll .arrow-left:hover:after,
.nav-tabs.side-scroll .arrow-right:hover:after {
  border-color: var(--py-color-white);
}
.nav-tabs.active-tab-border .tab-content {
  border-top: solid 1px var(--py-color-grey-light);
  margin-top: 0 !important;
  padding-top: 10px;
}
.nav-tabs.active-tab-border.nav-tabs .nav-item .nav-link {
  border-top: 0;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: var(--py-color-white);
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 15px !important;
}
.nav-tabs.active-tab-border.nav-tabs .nav-item .nav-link.active {
  border-bottom-color: var(--py-color-global-active);
}
.nav-tabs.active-tab-border.dark-border.nav-tabs .nav-item .nav-link.active {
  border-bottom-color: var(--py-color-primary-dark);
}
.nav-tabs.mobile-menu {
  border-top: solid 1px var(--py-color-grey-light);
  border-bottom: solid 1px var(--py-color-grey-light);
  display: flex;
}
.nav-tabs.mobile-menu .nav-item {
  flex: 1 !important;
  display: flex !important;
  text-align: center !important;
  justify-content: center !important;
}
.nav-tabs.mobile-menu .nav-item .nav-link {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  margin: 0 !important;
}
.nav-tabs .nav-item {
  margin-bottom: 0px !important;
}
.nav-tabs .nav-item[ng-reflect-disabled=true] {
  display: none !important;
}
.nav-tabs .nav-item.disabled {
  display: none !important;
}
.nav-tabs .nav-item .nav-link {
  flex: 1 !important;
  border: none;
  display: flex !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 0px !important;
  margin-right: 15px !important;
  margin-bottom: 0;
  text-align: center !important;
  justify-content: center !important;
  color: var(--py-color-grey-dark);
  font-family: var(--py-font-secondary);
  border-top-color: transparent;
  border-top-width: 3px;
  border-top-style: solid;
  align-items: center;
}
.nav-tabs .nav-item .nav-link i {
  margin-right: 5px !important;
  display: flex !important;
  align-items: center !important;
}
.nav-tabs .nav-item .nav-link:hover {
  color: var(--py-color-primary-500);
}
.nav-tabs .nav-item .nav-link:link, .nav-tabs .nav-item .nav-link:visited {
  outline: 0;
}
.nav-tabs .nav-item .nav-link.active {
  color: var(--py-color-black);
  border-top-color: var(--py-color-primary-500);
}
.nav-tabs .nav-item .nav-link.disabled {
  display: none !important;
}

body > .dropdown,
body > .dropup {
  z-index: 9 !important;
}

.dropdown-menu {
  z-index: 9;
}
@media (max-width: 992px) {
  .dropdown-menu {
    z-index: 1052;
  }
}

.text-primary {
  color: var(--py-color-primary-500) !important;
}