.btn {
  font-size: 1rem;
  line-height: $line-height;
  padding: $padding-y $padding-x;
  border-radius: 4px;
  border-width: 2px;
  box-shadow: $box-shadow;

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &-link {
    box-shadow: none;
  }

  &:not(.btn-link):focus {
    box-shadow: $box-shadow;
  }

  &:not(.btn-icon):not(.btn-sm) {
    min-width: 10em;

    @include media-breakpoint-down(sm) {
      min-width: 5em;
    }
  }

  &:not(.btn-icon) {
    i {
      font-size: 1.15em;
    }

    & > i:first-child {
      margin-right: 5px;
    }

    & > i:last-child {
      margin-left: 5px;
    }
  }

  &-sm {
    font-size: 0.875rem;
  }

  &-icon {
    padding: $icon-padding;
    font-size: $icon-size;
    border-radius: 4px;

    i {
      width: $line-height;
      height: $line-height;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  i {
    vertical-align: middle;
    line-height: 0;
    svg {
      vertical-align: baseline;
    }
  }
}
