.nav-tabs {
  border-bottom: none;

  &.side-scroll {
    overflow-y: scroll;
    white-space: nowrap;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.show-shadow-left,
    &.show-shadow-right {
      position: static;
    }

    &.show-shadow-right::after,
    &.show-shadow-left::before {
      content: ' ';
      position: absolute;
      right: 30px;
      top: 0;
      height: 100%;
      width: 28px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--py-color-white) 100%),
        linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.24) 100%);
    }

    &.show-shadow-left::before {
      right: unset;
      left: 30px;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, var(--py-color-white) 100%),
        linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.24) 100%);
    }

    .arrow-left,
    .arrow-right {
      display: none;
      cursor: pointer;
      position: absolute;
      margin-top: 17px;
      min-width: 20px;
      height: 20px;
      border: 2px solid var(--py-color-black);
      border-radius: 50%;
      z-index: 5;
    }

    .arrow-left-background,
    .arrow-right-background {
      display: none;
      z-index: 4;
      background-color: var(--py-color-white);
      position: absolute;
      min-width: 30px;
      height: 100%;
    }

    .arrow-right-background {
      right: 0;
    }

    .arrow-left-background {
      left: 0;
    }

    .arrow-left:after,
    .arrow-right:after {
      content: '';
      position: absolute;
      display: none;
      margin-top: 5px;
      margin-left: 5px;
      width: 6px;
      height: 6px;
      border-top: 2px solid var(--py-color-black);
      border-right: 2px solid var(--py-color-black);
      -moz-transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }

    .arrow-right:after {
      content: '';
      margin-left: 4px;
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .arrow-left {
      left: 7px;
    }

    .arrow-right {
      right: 7px;
    }

    &.show-shadow-left {
      .arrow-left,
      .arrow-left:after,
      .arrow-left-background {
        display: inline-block;
      }
    }

    &.show-shadow-right {
      .arrow-right,
      .arrow-right:after,
      .arrow-right-background {
        display: inline-block;
      }
    }

    .arrow-left:hover,
    .arrow-right:hover {
      background-color: var(--py-color-black);
      &:after {
        border-color: var(--py-color-white);
      }
    }
  }

  &.active-tab-border {
    .tab-content {
      border-top: solid 1px var(--py-color-grey-light);
      margin-top: 0 !important;
      padding-top: 10px;
    }

    &.nav-tabs .nav-item .nav-link {
      border-top: 0;
      border-bottom-width: 5px;
      border-bottom-style: solid;
      border-bottom-color: var(--py-color-white);
      padding-left: 5px !important;
      padding-right: 5px !important;
      padding-top: 15px !important;

      &.active {
        border-bottom-color: var(--py-color-global-active);
      }
    }

    &.dark-border {
      &.nav-tabs .nav-item .nav-link {
        &.active {
          border-bottom-color: var(--py-color-primary-dark);
        }
      }
    }
  }

  &.mobile-menu {
    border-top: solid 1px var(--py-color-grey-light);
    border-bottom: solid 1px var(--py-color-grey-light);
    display: flex;

    .nav-item {
      flex: 1 !important;
      display: flex !important;
      text-align: center !important;
      justify-content: center !important;

      .nav-link {
        padding-top: 14px !important;
        padding-bottom: 14px !important;
        margin: 0 !important;
      }
    }
  }

  .nav-item {
    margin-bottom: 0px !important;

    &[ng-reflect-disabled='true'] {
      display: none !important;
    }

    &.disabled {
      display: none !important;
    }

    .nav-link {
      flex: 1 !important;
      border: none;
      display: flex !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      border-radius: 0px !important;
      margin-right: 15px !important;
      margin-bottom: 0;
      text-align: center !important;
      justify-content: center !important;
      color: var(--py-color-grey-dark);
      font-family: var(--py-font-secondary);
      border-top-color: transparent;
      border-top-width: 3px;
      border-top-style: solid;
      align-items: center;

      i {
        margin-right: 5px !important;
        display: flex !important;
        align-items: center !important;
      }

      &:hover {
        color: var(--py-color-primary-500);
      }

      &:link,
      &:visited {
        outline: 0;
      }

      &.active {
        color: var(--py-color-black);
        border-top-color: var(--py-color-primary-500);
      }

      &.disabled {
        display: none !important;
      }
    }
  }
}
