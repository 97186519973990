@import '../../declarations';

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-row {
  &:hover {
    background: #fafafa;
  }
}

.ant-table {
  width: 100%;
  border-radius: 3px;
}

.ant-table-title {
  padding: 10px;
  font-size: 16px;
  line-height: 1.38;
  font-family: var(--py-font-secondary);
  color: var(--py-color-black);
  background-color: var(--py-color-white);
  display: none;

  @include media-breakpoint-down(sm) {
    display: block;
    border-bottom: solid 1px var(--py-color-grey-light);
  }
}

.ant-table-tbody {
  @include media-breakpoint-down(sm) {
    .ant-table-row {
      display: block;
      border: 0;
      border-bottom: solid 1px var(--py-color-grey-light);

      .ant-table-cell {
        border: none;
        display: block;
        font-size: 16px;
        line-height: 1.5;
        padding-top: 0px;
        padding-bottom: 0px;
        font-family: var(--py-font-primary);
        color: var(--py-color-grey-dark);

        &::before {
          width: 50%;
          float: left;
          font-size: 14px;
          line-height: 1.57;
          font-family: var(--py-font-secondary);
          content: attr(data-label);
          color: var(--py-color-black);
        }

        &:first-child {
          padding-top: 20px;
          padding-bottom: 10px;
        }
        &:last-child {
          padding-bottom: 20px;
        }
      }
    }
  }
}

.ant-table-row {
  .ant-table-cell {
    font-size: 16px;
    line-height: 2;
    color: var(--py-color-black);
  }
}

.ant-table-thead {
  .ant-table-cell {
    font-family: var(--py-font-secondary-bold);
    color: var(--py-color-grey-dark);
    background-color: var(--py-color-white);
    font-size: 14px;
    border: 0;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.ant-table-pagination {
  float: none;
  text-align: center;
  justify-content: center;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background: none;
  border: none;
  color: var(--py-color-black);

  a {
    &:hover {
      color: var(--py-color-black);
    }
  }
}

.ant-pagination-item {
  border: none;
  background: none;

  a {
    font-size: 14px;
    font-family: var(--py-font-secondary);

    &:hover {
      color: var(--py-color-black);
    }
  }
}

.ant-pagination-item-active {
  border-radius: 90px;
  background: var(--py-color-primary-dark);

  a {
    color: var(--py-color-white) !important;

    &:hover {
      color: var(--py-color-white);
    }
  }
}

.table-grey {
  font-family: var(--py-font-primary);
  font-size: 16px;
  width: 100%;

  thead {
    th {
      font-family: var(--py-font-secondary);
      background-color: var(--py-color-grey-100);
      font-size: 14px !important;
      color: var(--py-color-grey-500);
      border-bottom: 0px;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid var(--py-color-grey-100);
    }
  }

  th,
  td {
    padding: 1rem 0.75rem !important;
    text-align: left !important;
  }

  &-list {
    padding: 0 !important;
    border: 1px solid var(--py-color-grey-100);

    &-header {
      background-color: var(--py-color-grey-100);
    }

    &-row-header {
      font-family: var(--py-font-secondary-bold);
    }
  }
}
